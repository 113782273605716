





































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MintViewModel } from '../../viewmodels/mint-viewmodel'

@Observer
@Component({
  components: {
    'mint-countdown': () => import('@/modules/mint/components/process/mint-countdown.vue'),
    'whitelist-result-no-win': () => import('@/modules/mint/components/process/whitelist-result-no-win.vue'),
    'mint-nft-filled': () => import('@/modules/mint/components/process/mint-nft-filled.vue'),
    'mint-process-end': () => import('@/modules/mint/components/process/mint-process-end.vue'),
  },
})
export default class extends Vue {
  @Inject({}) vm!: MintViewModel
  isFilled = false
}
